/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {YouTube, Gallery, GalleryImage, gallerySettings, L} from "@src/style/mdxStyle.js";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "landscape-photography-movies-for-covid-19-lockdown",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#landscape-photography-movies-for-covid-19-lockdown",
    "aria-label": "landscape photography movies for covid 19 lockdown permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Landscape photography movies for COVID-19 lockdown"), "\n", React.createElement(_components.p, null, "Sitting at home is no fun, especially if usually you spend outdoors more time than inside. And while I can't do anything about lockdown itself - we all need to protect our relatives, friends and neighbors - here are some movies to watch that could inspire you to take some nice images once COVID-19 blows over."), "\n", React.createElement(_components.h2, {
    id: "photographers-on-the-job",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#photographers-on-the-job",
    "aria-label": "photographers on the job permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Photographers on the job"), "\n", React.createElement(_components.h3, {
    id: "quest-for-inspiration",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#quest-for-inspiration",
    "aria-label": "quest for inspiration permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Quest For Inspiration"), "\n", React.createElement(_components.p, null, "A very inspirational movie about french landscape photographer ", React.createElement(L, {
    t: "Alexandre Deschaumes",
    h: "https://www.alexandredeschaumes.com/"
  }), ". Movie was shot over the time of several years when videographer ", React.createElement(L, {
    t: "Mathieu Le Lay",
    h: "http://www.mathieulelay.com/"
  }), " was following Alexandre in his trips and filming experiences and thoughts."), "\n", React.createElement(_components.p, null, "https://player.vimeo.com/video/54003139"), "\n", React.createElement(_components.p, null, "Watch the full movie in ", React.createElement(L, {
    t: "Vimeo On-Demand",
    h: "https://vimeo.com/ondemand/thequestforinspiration"
  }), "."), "\n", React.createElement(_components.h3, {
    id: "in-the-starlight",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#in-the-starlight",
    "aria-label": "in the starlight permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "In the starlight"), "\n", React.createElement(_components.p, null, "One more movie shot by ", React.createElement(L, {
    t: "Mathieu Le Lay",
    h: "http://www.mathieulelay.com/"
  }), " focusing on the experiences of ", React.createElement(L, {
    t: "Paul Zizka",
    h: "https://zizka.ca/"
  }), ". As you have guessed from the name, movie highlights nightscape photography a lot."), "\n", React.createElement(_components.p, null, "https://player.vimeo.com/video/253335421"), "\n", React.createElement(_components.p, null, "Watch the full movie in ", React.createElement(L, {
    t: "Vimeo On-Demand",
    h: "https://vimeo.com/ondemand/inthestarlight"
  }), "."), "\n", React.createElement(_components.h3, {
    id: "salt",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#salt",
    "aria-label": "salt permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Salt"), "\n", React.createElement(_components.p, null, "Movie highlights multiple trips of award-winning photographer ", React.createElement(L, {
    t: "Murray Fredericks",
    h: "https://murrayfredericks.com/"
  }), " to the Lake Eyre in South Australia in pursuit to create ", React.createElement(L, {
    t: "Salt photography series",
    h: "https://murrayfredericks.com/project/salt/"
  }), "."), "\n", React.createElement(_components.p, null, "https://player.vimeo.com/video/17151279"), "\n", React.createElement(_components.p, null, "Watch the full movie on ", React.createElement(L, {
    t: "Amazon Prime",
    h: "https://www.amazon.com/Salt-Murray-Fredericks/dp/B00IRXHYTM"
  }), "."), "\n", React.createElement(_components.h3, {
    id: "portrait-of-nature",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#portrait-of-nature",
    "aria-label": "portrait of nature permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Portrait Of Nature"), "\n", React.createElement(_components.p, null, "A thoughtful movie about Japanese photographer ", React.createElement(L, {
    t: "Nobuyuki Kobayashi",
    h: "https://zenne-inc.com/en.html"
  }), ". Japanese have a special relationship with nature and in this movie Nobuyuki shows how he tries to portrait nature as it could be perceived by our ancestors."), "\n", React.createElement(_components.p, null, "Full movie is embeded here, you can also watch it on Vimeo."), "\n", React.createElement(_components.p, null, "https://player.vimeo.com/video/91495217"), "\n", React.createElement(_components.h3, {
    id: "light-hunter",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#light-hunter",
    "aria-label": "light hunter permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Light Hunter"), "\n", React.createElement(_components.p, null, "An hour long documentary about ", React.createElement(L, {
    t: "Stefan Forster",
    h: "https://www.stefanforster.com/"
  }), " photographing in Iceland. Heavy-lifting drones with D800, kayaking in the ice-cold water and beauty of Iceland around."), "\n", React.createElement(YouTube, {
    video: "UhwOSwxtnyg"
  }), "\n", React.createElement(_components.p, null, "Watch full movie on ", React.createElement(L, {
    t: "Light Hunter homepage",
    h: "https://www.stefanforster.com/film-intro?lang=en#lighthunter-intro"
  }), "."), "\n", React.createElement(_components.h2, {
    id: "with-a-focus-on-environment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#with-a-focus-on-environment",
    "aria-label": "with a focus on environment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "With a focus on environment"), "\n", React.createElement(_components.h3, {
    id: "chasing-ice",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#chasing-ice",
    "aria-label": "chasing ice permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Chasing Ice"), "\n", React.createElement(_components.p, null, "Famous movie about how ", React.createElement(L, {
    t: "James Balog",
    h: "http://jamesbalog.com/"
  }), " with the team adventure across Arctic in a pursuit to capture climate change in glaciers."), "\n", React.createElement(YouTube, {
    video: "eIZTMVNBjc4"
  }), "\n", React.createElement(_components.p, null, "Watch the full movie on ", React.createElement(L, {
    t: "Amazon Prime",
    h: "https://www.amazon.com/Chasing-Ice-James-Balog/dp/B00CNL78JA"
  })), "\n", React.createElement(_components.h3, {
    id: "manufactured-landscapes",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#manufactured-landscapes",
    "aria-label": "manufactured landscapes permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Manufactured Landscapes"), "\n", React.createElement(_components.p, null, "This movie focuses on how industry changes the landscape. Following ", React.createElement(L, {
    t: "Edward Burtynsky",
    h: "https://www.edwardburtynsky.com/"
  }), " trip into China, depicting great changes of China industrial revolution."), "\n", React.createElement(YouTube, {
    video: "KVybNCPzG7M"
  }), "\n", React.createElement(_components.p, null, "Watch the full movie on ", React.createElement(L, {
    t: "Amazon Prime",
    h: "https://www.amazon.com/Manufactured-Landscapes-Edward-Burtynsky/dp/B011MV5KJQ"
  })), "\n", React.createElement(_components.h3, {
    id: "watermark",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#watermark",
    "aria-label": "watermark permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Watermark"), "\n", React.createElement(_components.p, null, "A second movie by ", React.createElement(L, {
    t: "Edward Burtynsky",
    h: "https://www.edwardburtynsky.com/"
  }), " and filmmaker Jennifer Baichwal, this time focusing about water and how humanity have impacted it."), "\n", React.createElement(YouTube, {
    video: "QOPLs_ogF-0"
  }), "\n", React.createElement(_components.p, null, "Watch the full movie on ", React.createElement(L, {
    t: "Amazon Prime",
    h: "https://www.amazon.com/Watermark-Edward-Burtynsky/dp/B00LM470KQ"
  }), " or ", React.createElement(L, {
    t: "Official website",
    h: "http://www.watchwatermarkmovie.com/"
  }), "."), "\n", React.createElement(_components.h3, {
    id: "amos-the-impossible-shot",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#amos-the-impossible-shot",
    "aria-label": "amos the impossible shot permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Amos, the impossible shot"), "\n", React.createElement(_components.p, null, "A movie about how ", React.createElement(L, {
    t: "Amos Nachoum",
    h: "https://amosphotography.com/"
  }), " tries to capture an illusive photograph of endangered polar bear swimming in ice cold water."), "\n", React.createElement(YouTube, {
    video: "LTL2qql8XpM"
  }), "\n", React.createElement(_components.h2, {
    id: "bonus",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bonus",
    "aria-label": "bonus permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bonus"), "\n", React.createElement(_components.h3, {
    id: "tales-by-light",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tales-by-light",
    "aria-label": "tales by light permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tales by Light"), "\n", React.createElement(YouTube, {
    video: "g4KX14KV0xY"
  }), "\n", React.createElement(_components.h3, {
    id: "moving-art",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#moving-art",
    "aria-label": "moving art permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Moving Art"), "\n", React.createElement(YouTube, {
    video: "udtr6nBgGfs"
  }), "\n", React.createElement(_components.h3, {
    id: "before-you-wake-up",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#before-you-wake-up",
    "aria-label": "before you wake up permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Before You Wake Up"), "\n", React.createElement(_components.p, null, "https://player.vimeo.com/video/115366037"), "\n", React.createElement(_components.h3, {
    id: "did-i-miss-anything",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#did-i-miss-anything",
    "aria-label": "did i miss anything permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Did I miss anything?"), "\n", React.createElement(_components.p, null, "Send me a message on Facebook or drop me an email if you think something is missing from the list"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
